import _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {Button, TextField} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import * as NetworkStore from 'stores/Network';

import 'less/login.less';

@connect(() => ({}), NetworkStore.actionCreators)
export default class ForgotUsername extends React.Component {
    static propTypes = NetworkStore.ActionShape;
    
    constructor(props) {
        super(props);
        this.state = {
            firstLoad: true,
            errorMessage: '',
            step: 1,
            email: '',
            errors: {},
            touched: {email: false},
        };
    }

    validate(values) {
        let errors = {};

        this.setState({firstLoad: false});

        if (!values.email) {
            errors.email = 'Email is required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                values.email
            )
        ) {
            errors.email = 'Invalid email address';
        }
    
        return errors;
    }

    updateErrors(values) {
        const errors = this.validate(values);
        this.setState({errors});
        return Object.keys(errors).length;
    }

    displayErrors(currentField = '') {
        const {touched: alreadyTouched, ...otherState} = this.state;
        const errors = this.validate(this.state);
        let touched = _.mapValues(alreadyTouched, (v, k) => !!(alreadyTouched[k] || otherState[k] || k === currentField));

        this.setState({errors, touched});
        return Object.keys(errors).length;
    }

    sendEmail(e) {
        e.preventDefault();
        const values = this.state;
        const {forgotUsername} = this.props;

        forgotUsername(values).then(({response: {status, data}}) => {
            if (data.result === 0) { // success
                this.setState({step: 2});
            } else { // fail
                if (data.result === 1) {
                    this.setState({errorMessage: 'We are sorry, but we do not have that email address on file.'});
                } else if (data.result === 2) {
                    this.setState({errorMessage: 'Something went wrong'});
                } else if (data.result === 3) {
                    this.setState({errorMessage: 'Multiple contacts found for email'});
                }

                this.setState({step: 3});
            }            
        
        });
    }

    handleFieldChange(field, e) {
        let newState = {...this.state, [field]: e.target.value};
        this.setState(newState);
        this.updateErrors(newState);
    }
 
    render() {
        const {email, errors, touched, firstLoad, step, errorMessage} = this.state;
        return (<div className="login-form" >
            <PageMetaData title="Forgot Username" />

            {step === 1
                ? <div className="login-content" style={{marginBottom: '30px'}}>
                    <h1>Forgot Username</h1>
                    <form onSubmit={::this.sendEmail}>
                        <div>
                            <TextField type="text" id="email" autoFocus={true} tabIndex="1" value={email} onChange={e => ::this.handleFieldChange('email', e)} onBlur={() => ::this.displayErrors('email')} showErrorMessage={errors.email && touched.email}
                                placeholder="Email" label="Email" />
            
                            <Button disabled={firstLoad || Object.keys(errors).length > 0} secondary={true} type="submit">Send</Button>
                        </div>
                    </form>
                </div> : '' }

            {step === 2
                ? <div className="login-content" style={{marginBottom: '30px'}}>
                    <h1 style={{marginBottom: '25px'}}>We have sent your username to the specified email address.</h1>
                    <NavLink style={{marginRight: '10px', marginTop: '35px', marginBottom: '30px'}} to="/" className="pull-left forgot-username">Return to Homepage</NavLink> 
                </div>
                : ''}

            {step === 3
                ? <div className="login-content" style={{marginBottom: '30px'}}>
                    <h1 style={{marginBottom: '25px'}}>{errorMessage}</h1>
                    <NavLink onClick={() => this.setState({step: 1, firstLoad: true})} style={{marginRight: '10px', marginTop: '35px', marginBottom: '30px'}} to="/forgotusername" className="pull-left forgot-username">Please try again</NavLink> 
                </div>
                : ''}
    
        </div>);
    }
}
